import { ref, reactive, toRefs, computed, watch } from '@vue/composition-api'
import { fetchStats } from '../api/fetchStats'
import { fetchTopUsers } from '../api/fetchTopUsers'

const mainEvents = ref([])
const mainStats = ref({ players: 0, tries: 0, finalists: 0 })

const state = reactive({
  isLoading: false,
  error: null,
})

const topPlayers = ref(null)

export default function useStats() {
  const updateStats = async () => {
    state.isLoading = true
    try {
      const { events, ...stats } = await fetchStats()
      const filteredEvents = events.filter((el) => {
        return el.type !== 'unsolved' && el.type !== 'dataset_downloaded'
      })
      mainEvents.value = filteredEvents
      mainStats.value = stats
    } catch (e) {
      state.error = e
    } finally {
      state.isLoading = false
    }
  }

  const loadTopUsers = async () => {
    try {
      const response = await fetchTopUsers()
      topPlayers.value = response
    } catch (e) {
      console.log(e)
    }
  }

  return {
    ...toRefs(state),
    updateStats,
    mainEvents,
    mainStats,
    loadTopUsers,
    topPlayers,
  }
}
