<style lang="scss" scoped>
.top-container {
  @apply pt-8 w-full;
  background: linear-gradient(180deg, #060606 0%, rgba(22, 22, 22, 0.76) 100%);
  border: 1px solid #313131;
  box-sizing: border-box;
  border-radius: 8px;
}
.title {
  @apply font-graphik_lv_regular px-5 text-left mb-10;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
}
.top {
  @apply w-full text-left;
  .place {
    @apply pl-5 pr-5 md:pr-14 text-center;
    width: 100px;
  }
  .score {
    @apply pl-5;
    width: 130px;
  }
  .username {
    @apply flex items-center pr-5;
  }
  .photo {
    @apply rounded-full bg-contain bg-center bg-no-repeat mr-4;
    --size: 32px;
    width: var(--size);
    height: var(--size);
  }
  tr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  td {
    min-height: 51px;
  }
  thead {
    @apply font-druk_text_cy;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
  }
  tbody {
    @apply font-graphik_lv_regular;
    color: #ffffff;
    font-size: 16px;
    .place {
      @apply font-druk_text_cy;
      font-size: 18px;
    }
    td {
      @apply py-2;
    }
  }
}
@scraan md {
  .top {
    tbody {
      font-size: 18px;
      .place {
        font-size: 24px;
      }
    }
  }
}
</style>

<template>
  <div class="top-container">
    <div class="title" v-html="title"></div>
    <table class="top">
      <thead>
        <tr>
          <td class="place text-left">МЕСТО</td>
          <td>ИГРОК</td>
          <td>КОЛ-ВО ПОПЫТОК</td>
          <td class="score">СЧЕТ</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(player, idx) in players" :key="idx">
          <td class="place">{{ player.place }}</td>
          <td class="username">
            <div
              v-if="player.photo"
              class="photo"
              :style="{ backgroundImage: `url('${player.photo}')` }"
            ></div>
            {{ player.username }}
          </td>
          <td>{{ player.tries }}</td>
          <td class="score">{{ player.score }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TopTable',
  components: {},
  data() {
    return {}
  },
  props: {
    title: { type: String, default: 'define title' },
    players: { type: Array, default: () => [] },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
