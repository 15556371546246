<style lang="scss">
.ending {
  &-header {
    @apply font-druk_text_cy;
    color: #ffffff;
    margin-bottom: 40px;
    &__avatar {
      margin: 0 auto;
      width: 128px;
      border-radius: 50%;
      background-color: #01c0f2;
      margin-bottom: 26px;
    }
    &__name {
      font-weight: normal;
      font-size: 42px;
      line-height: 42px;
    }
    & h1 {
      font-weight: normal;
      font-size: 106px;
      line-height: 133px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      & span {
        color: #00c1f5;
      }
    }
  }
  &-places {
    @apply flex sm:-mx-2 flex-wrap;
    margin-bottom: 32px;
    &__card {
      @apply flex-grow-0 flex-shrink-0 sm:mx-2 mb-4 xl:mb-0;
      position: relative;
      color: #ffffff;
      width: 100%;
      background: linear-gradient(
        180deg,
        #060606 0%,
        rgba(22, 22, 22, 0.76) 100%
      );
      border: 1px solid #313131;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 24px 20px;
      &:last-child {
        margin-right: 0px;
      }
      &-place {
        @apply font-druk_text_cy;
        font-size: 42px;
        line-height: 42px;
        text-align: left;
        margin-bottom: 4px;
      }
      &-desr {
        @apply font-graphik_lv_regular whitespace-nowrap;
        color: rgba(255, 255, 255, 0.5);
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        margin-bottom: 24px;
      }
      &-user {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        & img {
          width: 40px;
          border-radius: 50%;
          margin-right: 16px;
        }
        & p {
          @apply font-graphik_lv_regular;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
        }
      }
      &-prize {
        position: absolute;
        image-rendering: crisp-edges;
        top: -24px;
        right: 16px;
        height: 90px;
        width: auto;
      }
    }
  }
}
@screen sm {
  .ending {
    &-places {
      &__card {
        width: calc(100% / 2 - 16px);
      }
    }
  }
}
@screen xl {
  .ending {
    &-places {
      &__card {
        width: calc(100% / 4 - 16px);
      }
    }
  }
}
</style>

<template>
  <div class="ending mt-8 pb-10">
    <div v-if="topPlayers && topPlayers.my_result" class="ending-header">
      <img :src="topPlayers.my_result.photo" class="ending-header__avatar" />
      <p class="ending-header__name">{{ topPlayers.my_result.username }}</p>
      <h1>
        ты на
        <span>{{ topPlayers.my_result.place }}</span> месте
      </h1>
    </div>
    <div class="ending-places">
      <div
        v-for="player in topHight"
        :key="player.place"
        class="ending-places__card"
      >
        <p class="ending-places__card-place">{{ player.place }}</p>
        <p class="ending-places__card-desr">{{ player.title }}</p>
        <div class="ending-places__card-user">
          <img :src="player.photo" :alt="player.username" />
          <div class="text-left">
            <div>{{ player.username }}</div>
            <div class="font-graphik_lv_regular text-14 text-white opacity-50">
              {{ player.score }} ({{ player.tries }})
            </div>
          </div>
        </div>
        <img :src="player.image" class="ending-places__card-prize" />
      </div>
    </div>
    <div class="mb-8">
      <top-table
        title="Участники с 5 по 8 место получают рюкзак <span style='color: #00c1f5'>Wenger MX Professional 16”</span> и фирменный мерч"
        :players="topMiddle"
      />
    </div>
    <div class="mb-14">
      <top-table
        title="Участники с 9 по 16 место получают <span style='color: #00c1f5'>фирменный мерч</span>"
        :players="topLow"
      />
    </div>
    <final-tabs v-if="isAuthenticated" />
  </div>
</template>

<script>
import useUser from '@/composables/useUser'
import useStats from '@/composables/useStats'
import TopTable from '../../components/Final/TopTable.vue'
import { REWARDS } from '@/consts.js'
import FinalTabs from '@/components/Final/FinalTabs.vue'

export default {
  name: 'EndingPage',
  components: {
    TopTable,
    FinalTabs,
  },
  data() {
    return {
      rewards: REWARDS,
    }
  },
  props: {},
  setup(props, ctx) {
    const { user, isAuthenticated } = useUser()
    const { loadTopUsers } = useStats()
    const { topPlayers } = useStats()
    return {
      user,
      loadTopUsers,
      topPlayers,
      isAuthenticated,
    }
  },
  created() {
    this.loadTopUsers()
  },
  mounted() {
    console.log(this.user)
    console.log(this.topPlayers)
  },
  methods: {},
  computed: {
    topHight() {
      return (
        this.topPlayers?.winners
          ?.filter((item) => item.place < 5)
          .map((item) => {
            return {
              ...item,
              ...this.rewards.find((prize) => prize.placeId === item.place),
            }
          }) || []
      )
    },
    topMiddle() {
      return (
        this.topPlayers?.winners?.filter(
          (item) => item.place > 4 && item.place < 9
        ) || []
      )
    },
    topLow() {
      return (
        this.topPlayers?.winners?.filter(
          (item) => item.place > 8 && item.place < 17
        ) || []
      )
    },
  },
}
</script>
